




























import Vue from 'vue'
import TrackimoForm from '@/components/Dialogs/TrackerForms/Trackimo.vue'
import { mapActions } from 'vuex'

const props = {
  title: { type: String, default: '' },
  tooltip: {
    type: String,
    default: 'Add Tracker'
  }
}

export default Vue.extend({
  name: 'AddTrackerDialog',
  components: { TrackimoForm },
  props,
  data() {
    return {
      dialog: false as boolean,
      error: false as boolean,
      apiCallInProgress: false as boolean,
      errorMessage: null as boolean
    }
  },
  methods: {
    ...mapActions('trackers', {
      addTrackimoTracker: 'addTrackimoTracker',
      getTrackimoTrackers: 'getTrackimoTrackers'
    }),
    onAddFormClose() {
      this.dialog = false
      this.error = false
      this.errorMessage = null
    },
    async addTracker(payload) {
      this.apiCallInProgress = true
      const response = await this.addTrackimoTracker({
        username: payload.username,
        password: payload.password,
        name: payload.name,
        site_id: payload.site_id
      })

      if (!response.error) {
        await this.getTrackimoTrackers(payload.site_id)
        this.onAddFormClose()
      } else {
        this.error = true
        this.errorMessage = response.data
      }
      this.apiCallInProgress = false
    }
  }
})
